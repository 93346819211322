import React from 'react';
import { Badge, Container } from 'reactstrap';
import axios from 'axios';
import Moment from 'react-moment';
import Clips from './Clips';

export default class PostShow extends React.Component {
  static defaultProps = {
    post: {},
  };

  constructor(props, railsContext) {
    super(props);
    this.state = {
      post: props.post,
    };
  }

  componentDidMount() {
    if (this.props.match) {
      axios
        .get(
          `https://docker-rails.ledermann.dev/api/v1/posts/${this.props.match.params.id}.json`
        )
        .then((response) => {
          this.setState({ post: response.data.post });
        });
    }
  }

  render() {
    return (
      <Container>
        <h1 className="display-4">{this.state.post.title}</h1>
        <Badge
          color="secondary"
          tag="p">
          <Moment fromNow>{this.state.post.updated_at}</Moment>
        </Badge>

        <Clips clips={this.state.post.clips} />

        <div dangerouslySetInnerHTML={{ __html: this.state.post.content }} />
      </Container>
    );
  }
}
