import React from 'react';
import { Router, Route } from 'react-router-dom';
import history from './history';

import AppNav from './components/AppNav';
import PostListing from './components/PostListing';
import PostShow from './components/PostShow';
import AppFooter from './components/AppFooter';

import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

export default class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Router history={history}>
          <div>
            <AppNav />

            <Route
              exact
              path="/"
              render={(props) => (
                <PostListing
                  {...props}
                  source="https://docker-rails.ledermann.dev/api/v1/posts.json"
                />
              )}
            />

            <Route
              exact
              path="/posts/:id"
              component={PostShow}
            />

            <AppFooter />
          </div>
        </Router>
      </div>
    );
  }
}
