import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

export default class AppNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    return (
      <Navbar
        color="dark"
        expand="md"
        dark
        fixed="top">
        <Container>
          <NavbarBrand
            tag={Link}
            to="/">
            DockerReact
          </NavbarBrand>

          <NavbarToggler onClick={this.toggle} />

          <Collapse
            isOpen={this.state.isOpen}
            navbar>
            <Nav
              className="ml-auto"
              navbar>
              <NavItem>
                <NavLink href="/components/">Components</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://github.com/ledermann/docker-react">
                  GitHub
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}
