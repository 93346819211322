import React from 'react';
import { Container } from 'reactstrap';

export default class AppFooter extends React.Component {
  render() {
    return (
      <footer>
        <Container className="text-center">
          Frontend built with React v{React.version}
        </Container>
      </footer>
    );
  }
}
