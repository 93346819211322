import React from 'react';
import PropTypes from 'prop-types';
import Gallery from 'react-grid-gallery';

export default class Clips extends React.Component {
  static propTypes = {
    clips: PropTypes.array.isRequired,
  };

  static defaultProps = {
    clips: [],
  };

  render() {
    const IMAGES = this.props.clips.map(function (clip) {
      return {
        src: clip.large.url,
        thumbnail: clip.thumbnail.url,
        thumbnailWidth: 400,
        thumbnailHeight: 400,
      };
    });

    return (
      <div>
        <div style={{ overflow: 'auto' }}>
          <Gallery
            images={IMAGES}
            enableImageSelection={false}
          />
        </div>

        <hr />
      </div>
    );
  }
}
