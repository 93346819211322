import React from 'react';
import PropTypes from 'prop-types';
import { parse } from 'qs';
import { Table } from 'reactstrap';
import { Waypoint } from 'react-waypoint';
import PostRow from './PostRow';
import Header from './Header';
import axios from 'axios';
import { Icon } from 'react-fa';
import { Container } from 'reactstrap';

export default class PostListing extends React.Component {
  static propTypes = {
    source: PropTypes.string.isRequired,
  };

  constructor(props, railsContext) {
    super(props);
    this.state = {
      page: 0,
      eof: false,
      posts: [],
      query_string: this.params('q'),
    };
  }

  params(key) {
    const params = parse(this.props.location.search.substr(1));
    return params[key];
  }

  fetchPosts = () => {
    if (!this.state.eof) {
      axios
        .get(this.props.source, {
          params: {
            page: this.state.page + 1,
            q: this.state.query_string,
          },
        })
        .then((response) => {
          if (response.data.posts.length > 0) {
            this.setState((prevState, props) => {
              return {
                page: prevState.page + 1,
                posts: prevState.posts.concat(response.data.posts),
              };
            });
          } else {
            this.setState({ eof: true });
          }
        });
    }
  };

  render() {
    let spinner = null;
    if (!this.state.eof) {
      spinner = (
        <div className="text-center">
          <Icon
            spin
            name="spinner"
            size="3x"
          />
        </div>
      );
    }

    return (
      <Container>
        <Header />

        <Table>
          <thead>
            <tr className="thead-inverse">
              <th>Content</th>
              <th className="text-right text-nowrap d-none d-md-table-cell">
                Last Update
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.posts.map(function (post) {
              return (
                <PostRow
                  key={post.id}
                  post={post}
                />
              );
            })}
          </tbody>
        </Table>

        <Waypoint onEnter={this.fetchPosts}>{spinner}</Waypoint>
      </Container>
    );
  }
}
