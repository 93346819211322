import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { Icon } from 'react-fa';
import Moment from 'react-moment';
import history from '../history';

export default class PostRow extends React.Component {
  static propTypes = {
    post: PropTypes.object.isRequired,
  };

  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      formatted_content: this.formatted_content(),
    };
  }

  formatted_content() {
    let icon = null;
    if (this.props.post.clips_count > 0) {
      icon = <Icon name="picture-o" />;
    }

    return (
      <span>
        {icon} <strong>{this.props.post.title}</strong>{' '}
        {this.props.post.content}
      </span>
    );
  }

  handleClick = (e) => {
    const href = e.currentTarget.getAttribute('data-href');
    history.push(href);
  };

  render() {
    return (
      <tr
        onClick={this.handleClick}
        data-href={`/posts/${this.props.post.slug}`}>
        <td>{this.state.formatted_content}</td>
        <td className="text-right d-none d-md-table-cell text-nowrap">
          <Badge color="secondary">
            <Moment fromNow>{this.props.post.updated_at}</Moment>
          </Badge>
        </td>
      </tr>
    );
  }
}
